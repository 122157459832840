import React from 'react';

import { Typography, Card, CardHeader, CardBody } from "@material-tailwind/react";

import './NftList.css'

function NftList({Nfts, SelectedNfts, onNftSelect}) { 
  const imageOnError = (event) => {
    event.currentTarget.src = "/empty.png";
    event.currentTarget.className += " error";
  }
  
  return (
    <div className="grid grid-cols-1 md:grid-cols-6 items-center gap-6 mr-8">
        { Nfts.map(nft => (       
          <Card key={nft.id} value={nft.id} onClick={onNftSelect} className={`cursor-pointer rounded-md max-h-[24rem] overflow-hidden ${SelectedNfts.has(nft.id) ? 'liquifier-nft-selected':''}`}>
            <CardHeader floated={false} shadow={false} color="transparent" className="m-0 rounded-none"            >
                <img className='rounded-md w-full object-cover'
                  src={nft.image}
                  onError={imageOnError}
                  alt={nft.id}
                />
            </CardHeader>
            <CardBody className='p-4'>
                <div className="flex items-center justify-between mb-4">
                    <Typography className="ape-black text-base tracking-tight uppercase font-bold">
                      { nft.name }
                    </Typography>
                </div>
                <div className="grid grid-cols-2">
                  <Typography className="col-span-2 uppercase text-sm">Rank</Typography>
                  <Typography className="uppercase font-bold text-sm m-0">1/1074</Typography>
                  <Typography className="justify-self-end text-xs tracking-tight font-medium uppercase">details {'>'}</Typography>
                </div>
            </CardBody>                
            </Card>
        ))}
    </div>
  );
}

export default NftList;