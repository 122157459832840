import React, { useState, useEffect } from 'react';

import { Link } from "react-router-dom";

import { Alert, Typography, Card, CardHeader, CardBody, CardFooter, Button, Avatar, Tooltip } from "@material-tailwind/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
 
function Home({}) {
  const [collections, setCollections] = useState([{},{},{},{}]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [show, setShow] = useState(false)

  const handleError = (error, message) => {
    console.log('test error => \'' + message + '\'');
    setError(message);
    setShow(true);
  };

  useEffect(() => {
    const getLatestCollections = () => {
      fetch('/mock/latest_collections.json')
        .then(response => {
          if (response.status != 200) {
            throw new Error("Server responded with error!")
          }
          return response.json()
        })
        .then(collections => {
          setCollections(collections)
          setIsLoaded(true);
        }, err => {
          handleError(true, err);
        });
      };

    getLatestCollections()
  }, []);

  return (
    <main className="container mx-auto py-12">    
      <Alert show={show} color="red"
        icon={<ExclamationTriangleIcon className="h-6 w-6" />}
        dismissible={{
          onClose: () => {setShow(false)},
          action: (
            <Button variant="text" color="white" size="sm">
              Close
            </Button>
          ),
        }}
      >
        Sorry, something went wrong please try again.
      </Alert>


      <div className="grid grid-cols-1 gap-4 mx-6">
        {/* PRESENTATION */}          
        <Typography variant="h3" className="text-ape-black font-bold text-center mt-12 mb-2">
          Unleash the power of your NFTs
        </Typography>
        <Typography className="text-ape-black font-medium text-center mb-4">
          Liquifier unlocks the full potential of your NFTs and gives you 
          access to decentralized finance opportunities
        </Typography>
        
        {/* VIEW COLLECTIONS */}          
        <Link to="/collections" className="mx-auto">
          <Button className='uppercase mx-auto rounded-none shadow-black text-black bg-ape-yellow hover:shadow-ape-black'>
            View Collections
          </Button>
        </Link>
        
        {/* CARDS WHY */}          
        <div className="grid grid-cols-3 gap-4 my-16">
          <Card>
            <CardBody className="h-full bg-ape-lightgrey text-center rounded-md">
              <Typography variant="h4" className="text-ape-black tracking-tight font-extrabold mb-8">
                Collectors
              </Typography>
              <Typography className="text-ape-black tracking-tight text-sm font-medium">
                Use Liquifier to unlock more value from your NFTs.
                <br/>Convert your NFTS into ERC20 tokens and tap into the world
                of decentralized finance.
              </Typography>
            </CardBody>
          </Card>
          <Card>
            <CardBody className="h-full bg-ape-lightgrey text-center rounded-md">
              <Typography variant="h4" className="text-ape-black tracking-tight font-extrabold mb-8">
                Content creators
              </Typography>
              <Typography className="text-ape-black tracking-tight text-sm font-medium">
                Improve the reach and fairness of distribution for your NFT collection.
                Create instantly liquid markets for new content by launching on the Liquifier
                protocol
              </Typography>
            </CardBody>
          </Card>
          <Card>
            <CardBody className="h-full bg-ape-lightgrey text-center rounded-md">
              <Typography variant="h4" className="text-ape-black tracking-tight font-extrabold mb-8">
                Investors
              </Typography>
              <Typography className="text-ape-black tracking-tight text-sm font-medium">
                Access the most liquid NFT markets and track the price of particular
                categories of NFT, making speculating and investing in the NFT market
                a far simpler process.
              </Typography>
            </CardBody>
          </Card>
        </div>
        
        {/* LATEST COLLECTIONS */}          
        <div>
          <Typography className="text-ape-black font-bold text-left mt-12 mb-2 uppercase tracking-tight">
            Latest collections
          </Typography>
        </div>

        { isLoaded ? (
          <div className="grid grid-cols-4 gap-4">
            { collections.map(collection => (          
              <Card key={collection.id} className="max-w-[24rem] overflow-hidden">
                <CardHeader 
                  floated={false}
                  shadow={false}
                  color="transparent"
                  className="m-0 rounded-none"
                >
                  <img className='w-full object-cover'
                    src={collection.image}
                    alt={collection.id}
                  />
                </CardHeader>
                <CardBody>
                  <Typography className="ape-black text-base tracking-tight uppercase mb-2">
                    { collection.owner }
                  </Typography>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center -space-x-3">
                      <Typography className="ape-black text-base tracking-tight uppercase font-bold">
                        { collection.name }
                      </Typography>
                    </div>
                    <Link to={{ pathname: `/collection/${collection.id}` }} state={{col: collection}} className="flex items-center uppercase font-semibold focus:underline hover:underline">        
                      <Typography className="text-xs tracking-tight font-medium uppercase">view {'>'}</Typography>
                    </Link>
                  </div>
                </CardBody>                
              </Card>
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-4 gap-4">
          { collections.map((collection, index) => (
            <Card key={index} className="max-w-[24rem] overflow-hidden">
              <CardHeader floated={false} shadow={false} color="transparent" className="m-0 rounded-none" >
                <img className='animate-pulse w-full object-cover' src="/empty.png" alt="Loading.." />
              </CardHeader>
              <CardBody>
                <div className="grid grid-cols-4 gap-4 mb-4">
                  <div className="animate-pulse h-2 bg-ape-loading rounded col-span-2"></div>
                </div>
                <div className="grid grid-cols-4 gap-4">
                  <div className="animate-pulse h-2 bg-ape-loading rounded col-span-2"></div>
                  <div className="h-2 col-span-1"></div>
                  <div className="animate-pulse h-2 bg-ape-loading rounded col-span-1"></div>
                </div>
              </CardBody>                
            </Card>
          ))}
          </div>
        )}

        {/* PARTNERS */}
        <div className='mt-10'>
          <Typography className="text-ape-black font-bold text-left mt-12 mb-2 uppercase tracking-tight">
            Proud partners
          </Typography>
          <div className="grid grid-cols-3 gap-24 mx-4 ml-12 items-center mb-8">
            <img src="/partners/apedao.png" alt="apedao" className="h-22 object-cover" />
            <img src="/partners/shimmersea.png" alt="shimmersea" className="h-22 object-cover" />
            <img src="/partners/iotabots.png" alt="iotabots" className="h-22 object-cover" />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;