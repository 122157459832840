import React from "react";
import { NavLink, Link } from "react-router-dom";

import { Navbar, MobileNav, Typography, Button, } from "@material-tailwind/react";
import { WalletIcon } from "@heroicons/react/24/outline";

import './Navbar.css'

export default function NavBar({onConnectWallet}) {
  const [openNav, setOpenNav] = React.useState(false);
 
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);
  
  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <Typography as="li" variant="small" className="text-ape-black p-1">
        <NavLink to="/collections" className={`flex items-center uppercase font-semibold focus:underline hover:underline ${openNav ? "active" : ""}`}>
          Collections
        </NavLink>
      </Typography>
      <Typography as="li" variant="small" className="text-ape-black p-1">
        <NavLink to="/portfolio" className={`flex items-center uppercase font-semibold focus:underline hover:underline ${openNav ? "active" : ""}`}>
          Portfolio
        </NavLink>
      </Typography>
    </ul>
  );
 
  return (
      <Navbar className="sticky inset-0 z-10 h-max max-w-full rounded-none py-2 px-4 lg:px-8 lg:py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            <Link to="/">
              <img alt="Liquifier" className="flex h-[3rem] object-center" src="/logos/header.png"/>
            </Link>
            <div className="flex justify-start items-center px-4 lg:px-9 gap-4">
              <div className="mr-4 hidden lg:block">{navList}</div>
            </div>
          </div>
          <div className="flex justify-end">
            <Button onClick={onConnectWallet} className="flex items-center gap-3 text-ape-black bg-ape-yellow focus:shadow-ape-black hover:shadow-ape-black shadow-black">
              <WalletIcon strokeWidth={2} className="h-5 w-5" /> Connect Wallet
            </Button>
          </div>
        </div>
        
        <MobileNav open={openNav}>
          {navList}

          {/* When connected, implement this: https://www.material-tailwind.com/docs/react/menu */}
          <Button onClick={onConnectWallet} className="flex items-center gap-3 bg-ape-yellow focus:shadow-ape-black hover:shadow-ape-lightgrey shadow-ape-black sm mb-2">
            <WalletIcon strokeWidth={2} className="h-5 w-5" /> Connect Wallet
          </Button>
        </MobileNav>
      </Navbar>
  );
}