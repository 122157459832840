import { Typography } from "@material-tailwind/react";
 
export default function Footer() {
  return (
    <footer className="w-full bg-transparent mt-6 p-8">
      <div className="flex flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 text-center md:justify-between">
        <img alt="Liquifier" className="flex h-[4rem] object-center" src="/logos/footer.png"/>    
        <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
          <li>
            <Typography as="a" href="https://docs.apedao.finance/" className="text-ape-black transition-colors hover:underline focus:underline uppercase text-sm font-bold">
              Docs
            </Typography>
          </li>
          <li>
            <Typography as="a" href="#" className="text-ape-black transition-colors hover:underline focus:underline uppercase text-sm font-bold">
              Partnerships
            </Typography>
          </li>
          <li>
            <Typography as="a" href="https://discord.gg/WDxt6xZDDh" className="text-ape-black transition-colors hover:underline focus:underline uppercase text-sm font-bold">
              Discord
            </Typography>
          </li>
          <li>
            <Typography as="a" href="https://twitter.com/iotapes" className="text-ape-black transition-colors hover:underline focus:underline uppercase text-sm font-bold">
              Twitter
            </Typography>
         </li>
        </ul>
      </div>
    </footer>
  );
}