import React, { useCallback, useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import CollectionDetails from './CollectionDetails';
import CollectionSideBar from './CollectionSideBar';
import NftList from '../NftList/NftList';
import { select } from '@material-tailwind/react';

function Collection() {
  const { id } = useParams();
  const [ Collection, setCollection ] = useState({'media':[]})
  const [ Nfts, setNfts] = useState([])
  const [ Traits, setTraits ] = useState([]);
  const [ RandomNfts, setRandomNfts ] = useState(0);


  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [show, setShow] = useState(true);

  const [state, setState] = useState({
    displayedNfts: Nfts,
    filters: new Set(),
    selectedNfts: new Set()
  })

  const extractTraitsFromNftList = ((nfts) => {
    const properties =  Object.keys(nfts[0].properties);
    const traits = [];

    properties.map((property) => {
      let distinct_traits = [...new Set(nfts.map(({properties}) => properties[property] ))];
      
      distinct_traits = distinct_traits.map((temp_trait) => {
        const nftList = nfts.filter(({properties}) => { return (properties[property] == temp_trait) });

        return {
          "trait": temp_trait,
          "count": nftList.length
        }
      })

      traits.push({
        "property": property,
        "traits": distinct_traits
      });
      
    });

    setTraits(traits);
  })


  const handleError = (hasError, message) => {
    setError(message);
    setShow(true);
  };

  const handleQuantityChange = (event) => {
    let n = event.currentTarget.value;
    if (n === "") {
      setRandomNfts(0);
    } else {
      n = parseInt(n);
      if (n > 999) {
        n = 999
      } else if (n < 0) {
        n = 0
      }
      setRandomNfts(n);
    }
    // state.selectedNfts.clear();
  };


  const handleNftSelect = useCallback(event => {
    console.log("[i] Nft clicked:", event)

    setState(previousState => {
      let displayedNfts = previousState.displayedNfts || Nfts
      let filters = new Set(previousState.filters)
      let selectedNfts = new Set(previousState.selectedNfts)

      const nft_id = event.currentTarget.getAttribute('value')
      if (selectedNfts.has(nft_id)) {
        selectedNfts.delete(nft_id)
      } else {
        selectedNfts.add(nft_id)
      }

      return {
        displayedNfts,
        filters,
        selectedNfts
      }
    })
  }, [setState])
  
  const handleNftBuy = (event => {
    console.log("[i] Buy: TODO", event);
    event.target.blur();
  });

  const handleNftSell = (event => {
    console.log("[i] Sell: TODO", event);
    event.target.blur();
  });

  const handleFilterChange = useCallback((event, property) => {
    console.log("[i] Filters with trait '%s':'%s' to ", property, event.target.value, event.target.checked)
    setState(previousState => {
      let displayedNfts = Nfts
      let filters = new Set(previousState.filters)
      let selectedNfts = new Set(previousState.selectedNfts)
      
      if (event.target.checked) {
        filters.add(event.target.value)
      } else {
        filters.delete(event.target.value)
      }
      
      if (filters.size) {
        displayedNfts = displayedNfts.filter(({properties}) => {
          return filters.has(properties[property])
        })
      }
           
      return {
        displayedNfts,
        filters,
        selectedNfts
      }
    })
  }, [setState])



  
  useEffect(() => {
    const getCollectionDetails = () => {
        fetch('/mock/collection_infos.json')
        .then(response => {
            if (response.status != 200) {
                throw new Error("Server responded with error!")
            }
            return response.json()
        })
        .then(collection => {
            setCollection(collection)
            setIsLoaded(true);
        }, err => {
            handleError(true, err);
        });
        };

    getCollectionDetails()
  }, []);

  useEffect(() => {
    const getNtfsFromCollection = () => {
      fetch('/mock/nfts.json?id='+id)
        .then(response => {
          if (response.status != 200) {
            throw new Error("Server responded with error!")
          }
          return response.json()
        })
        .then(nfts => {
          setNfts(nfts);
          state.displayedNfts = nfts;
          extractTraitsFromNftList(nfts);
          setIsLoaded(true);
        }, err => {
          handleError(true, err);
        });
      };

    getNtfsFromCollection()
  }, []);




  return (
    <div className="overflow-hidden">
      <CollectionDetails Collection={Collection} />
      
      <div className='w-full border-t-[.1rem] border-ape-border mb-6'></div>
      
      <div className='grid grid-cols-6 mb-8'>
        <div className="col-span-1">
          <CollectionSideBar Collection={Collection} Traits={Traits} Nfts={state.displayedNfts} RandomNfts={RandomNfts} SelectedNfts={state.selectedNfts} onQuantityChange={handleQuantityChange} onNftSelect={handleNftSelect} onNftBuy={handleNftBuy} onNftSell={handleNftSell} onFilterChange={handleFilterChange} />
        </div>
        <div className="col-span-5">
          <NftList Nfts={state.displayedNfts} SelectedNfts={state.selectedNfts} onNftSelect={handleNftSelect} />
        </div>
      </div>
    </div>
  );
}

export default Collection;