import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import Home from './components/Home/Home';
import Collections from './components/Collections/Collections';
import Collection from './components/Collection/Collection';
import Portfolio from './components/Portfolio/Portfolio';
import NavBar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import ScrollToTop from './helpers/ScrollToTop';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

// function ErrorFallback({error, resetErrorBoundary}) {
//   const { resetBoundary } = useErrorBoundary();

//   return (
//     <div role="alert">
//       <p>Something went wrong:</p>
//       <pre>{error.message}</pre>
//       <button onClick={resetErrorBoundary}>Try again</button>
//     </div>
//   )
// }

function App() {
  
  const onConnectWallet = (event) => {
    console.log("[i] ConnectWallet: TODO", event);
    event.target.blur();
  }
  
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ToastContainer />
      <div className="bg-ape-brown">
        {/* <ErrorBoundary FallbackComponent={ErrorFallback}> */}
          <NavBar onConnectWallet={onConnectWallet} />

          <Routes>
              <Route path='/' exact element={<Home />} />
              <Route path='/collection/:id/' exact element={<Collection />} />
              <Route path='/collections' exact element={<Collections />} />
              <Route path='/portfolio' element={<Portfolio />} />
          </Routes>
          
          <Footer />
        
        {/* </ErrorBoundary> */}
      </div>
    </BrowserRouter>
  );
}

export default App;