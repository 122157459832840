import React, { useState, useEffect } from 'react';
import { Typography } from "@material-tailwind/react";

import CollectionList from '../CollectionList/CollectionList';

function Collections() {
    const [collectionList, setCollectionList] = useState([]);

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [show, setShow] = useState(true);

    const handleError = (hasError, message) => {
        setError(message);
        setShow(true);
    };

    useEffect(() => {
        const getCollectionDetails = () => {
            fetch('/mock/collections.json')
            .then(response => {
                if (response.status != 200) {
                    throw new Error("Server responded with error!")
                }
                return response.json()
            })
            .then(collections => {
                setCollectionList(collections)
                setIsLoaded(true);
            }, err => {
                handleError(true, err);
            });
        };

        getCollectionDetails()
    }, []);

    return (
        <div className='mb-4'>
            {/* Header */}
            <div className="flex items-center justify-between my-6 mx-10">
            <Typography className="text-xl font-bold uppercase">All Collections</Typography>
            </div>

            {/* Separator */}            
            <div className='w-full border-t-[.1rem] border-ape-border mb-6'></div>
            
            {/* Collections display */}
            <CollectionList Collections={collectionList} />
        </div>
    );
}

export default Collections;