import React from 'react';
import { Button, Typography } from '@material-tailwind/react';

function CollectionDetails({Collection}) {
    const onBuyAPEin = (event) => {
        console.log("[i] Buy APEin: TODO", event);
        event.target.blur();
      }
    
    return (
        <div className="flex items-center justify-between my-2 mx-8">
            <div className="flex items-center justify-start">
                <img alt={Collection.name} className="flex h-[3rem] object-center" src={Collection.logo}/>
                <div className="grid grid-rows-2 justify-start px-9">
                    <Typography className="mr-4 uppercase text-xl text-black font-bold">{Collection.name} </Typography>
                    <div className="flex items-center justify-between gap-2">
                    {Collection.media.map(({name, link}) => (
                        <a key={name} href={link}><img className="w-5" src={process.env.PUBLIC_URL +`/media/${name}.svg`} alt={name} /></a>
                    ))} 
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-end gap-6 my-4">
                <div className="flex flex-col">
                    <div className="flex uppercase">In Vault</div>
                    <div className='flex justify-end'><div className='font-semibold'>{Collection.vault}</div></div>
                </div>
                <div className="flex flex-col">
                    <div className="flex uppercase">Volume</div>
                    <div className='flex justify-end'><img alt="SMR" className="h-[1.4rem] mr-2 object-fill" src='/tokens/smr_logo.9dea87b006c4fc4e515ce24c2c130273.svg' /><div className='font-semibold'>{Collection.volume}</div></div>
                </div>
                <div className="flex flex-col mr-12">
                    <div className="flex uppercase">APEin Price</div>
                    <div className='flex justify-end'><img alt="SMR" className="h-[1.4rem] mr-2 object-fill" src='/tokens/smr_logo.9dea87b006c4fc4e515ce24c2c130273.svg' /><div className='font-semibold'>{Collection.apein_price}</div></div>
                </div>
                <Button size="sm" onClick={onBuyAPEin} className='flex uppercase my-auto rounded-none shadow-black text-black bg-ape-yellow hover:shadow-ape-black'>Buy APEin</Button>
            </div>
        </div>
    );
}

export default CollectionDetails;