import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Alert, Typography, Card, CardHeader, CardBody, CardFooter, Button, Avatar, Tooltip } from "@material-tailwind/react";

function CollectionList({Collections}) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [show, setShow] = useState(true);

  const handleError = (hasError, message) => {
    setError(message);
    setShow(true);
  };

  return (
    <div className="container mx-auto px-4 py-8 grid grid-cols-6 gap-4">
      { Collections.map(collection => (          
        <Card key={collection.id} className="max-w-[24rem] overflow-hidden">
          <CardHeader 
            floated={false}
            shadow={false}
            color="transparent"
            className="m-0 rounded-none"
          >
            <img className='w-full object-cover'
              src={collection.image}
              alt={collection.id}
            />
          </CardHeader>
          <CardBody className='p-4'>
            <Typography className="ape-black text-base tracking-tight uppercase">
              { collection.owner }
            </Typography>
            <div className="flex items-center justify-between">
              <Typography className="ape-black text-base tracking-tight uppercase font-bold">
                { collection.name }
              </Typography>
              <Link to={{ pathname: `/collection/${collection.id}` }} state={{col: collection}} className="flex items-center uppercase font-semibold focus:underline hover:underline">        
                <Typography className="text-xs tracking-tight font-medium uppercase">view {'>'}</Typography>
              </Link>
            </div>
          </CardBody>                
        </Card>
      ))}
    </div>
  );
}

export default CollectionList;