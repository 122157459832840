import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Button,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Typography,
} from "@material-tailwind/react";
import React, { useState  } from 'react';
import { InformationCircleIcon } from "@heroicons/react/24/outline";
 
import './CollectionSideBar.css';


function ArrowDownIcon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

function CollectionSideBar({Collection, Nfts, Traits, SelectedNfts, RandomNfts, onQuantityChange, onFilterChange, onNftBuy, onNftSell}) {
    const [open, setOpen] = useState(0);
    
    const handleOpen = (value) => {
      setOpen(open === value ? 0 : value);
    };

    const data = [
      {
        label: "Buy",
        value: "buy",
        html: <div className="grid gap-4 text-sm text-ape-black">
                <Typography className="text-sm font-bold">{SelectedNfts.size === 0 ? "Random" : "Target"} buy</Typography>
                  {SelectedNfts.size === 0 ? ( //TARGETED BUY
                    <div className="flex gap-3 items-center">
                      <div>Quantity:</div>
                      <input max="999" type="number" onChange={onQuantityChange} value={RandomNfts} className="rounded h-[1.4rem] w-12 align-right font-bold px-1" />
                    </div>
                  ) : ( //RANDOM BUY
                    <div className="flex gap-3 items-center">
                      <div>Fee:</div>
                      <div className='flex justify-start font-bold items-center'><img alt="APEin" className="h-[1.4rem] mr-2 object-cover" src='/tokens/apein_token_2.d05116aba0c058a60d29af74bcc63f19.svg' />{100}</div>
                      <div className='flex justify-end w-full font-bold items-center'><InformationCircleIcon className="justify-items-end h-[1.4rem]" /></div>  
                    </div>
                  )}
                <div className="grid grid-cols-2 gap-4">
                  <div>Total cost:</div>
                  <div className="flex justify-end">In my wallet</div>
                  <div className='flex justify-start font-bold items-center'><img alt="APEin" className="h-[1.4rem] mr-2 object-cover" src='/tokens/apein_token_2.d05116aba0c058a60d29af74bcc63f19.svg' />{SelectedNfts.size === 0 ? RandomNfts * 150 : SelectedNfts.size * 150}</div>
                  <div className='flex justify-end font-bold items-center'><img alt="APEin" className="h-[1.4rem] mr-2 object-cover" src='/tokens/apein_token_2.d05116aba0c058a60d29af74bcc63f19.svg' />{42}</div>
                  <Button size="sm" className='col-span-2 uppercase my-auto rounded-none shadow-black text-black bg-ape-yellow hover:shadow-ape-black' onClick={onNftBuy}>{SelectedNfts.size === 0 ? "Random buy" : "Target Buy"}</Button>
                </div>
              </div>
      },
      {
        label: "Sell",
        value: "sell",
        html: <div className="grid gap-4 text-sm text-ape-black">
                <Typography className="text-sm font-bold">Target sell</Typography>
                <div className="flex gap-3 items-center">
                  <div>Fee:</div>
                  <div className='flex justify-start font-bold items-center'><img alt="APEin" className="h-[1.4rem] mr-2 object-cover" src='/tokens/apein_token_2.d05116aba0c058a60d29af74bcc63f19.svg' />100</div>
                  <div className='flex justify-end w-full font-bold items-center'><InformationCircleIcon className="justify-items-end h-[1.4rem]" /></div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>Total cost:</div>
                  <div className="flex justify-end">In my wallet</div>
                  <div className='flex justify-start font-bold items-center'><img alt="APEin" className="h-[1.4rem] mr-2 object-cover" src='/tokens/apein_token_2.d05116aba0c058a60d29af74bcc63f19.svg' />13</div>
                  <div className='flex justify-end font-bold items-center'><img alt="APEin" className="h-[1.4rem] mr-2 object-cover" src='/tokens/apein_token_2.d05116aba0c058a60d29af74bcc63f19.svg' />900</div>
                  <Button size="sm" className='col-span-2 uppercase my-auto rounded-none shadow-black text-black bg-ape-yellow hover:shadow-ape-black' disabled={SelectedNfts.size === 0} onClick={onNftSell}>Sell</Button>
                </div>
              </div>,
      },
      {
        label: "Insights",
        value: "insights",
        html:  <div className="grid">
                TODO
               </div>,
      }
    ];

    return(
      <Tabs value={data[0].value} className="mx-8">
        <TabsHeader className="p-0 overflow-hidden rounded-none rounded-t-md bg-opacity-100 bg-ape-lightyellow"
          indicatorProps={{ className: "rounded-none bg-ape-darkyellow shadow-none" }}>
            {data.map(({ label, value }) => (
              <Tab key={value} value={value} className="p-2 uppercase font-bold text-sm">{label}</Tab>
            ))}
        </TabsHeader>
        <TabsBody>
          {data.map(({ value, html }) => (
            <TabPanel className="bg-ape-lightgrey" key={value} value={value}>
              {html}
            </TabPanel>
          ))}
        </TabsBody>

        <div className='pt-4 bg-ape-lightgrey mt-[0.085rem]'>
          <div className="mx-4 grid gap-4">
            <Typography className="text-sm font-bold text-ape-black uppercase">Filters</Typography>
            {Traits.map(({ property, traits }) => (
              <Accordion key={property} open={open === property} icon={<ArrowDownIcon id={property} open={open} />}>
                <AccordionHeader className="text-sm text-ape-black" onClick={() => handleOpen(property)}>
                  {property}
                </AccordionHeader>
                <AccordionBody>
                  {traits.map(({trait, count}) => (
                    <div key={trait} className="grid grid-cols-2">
                      <label className="flex">
                        <input type="checkbox" className="checkbox-round mr-2" id={trait} value={trait} onChange={e => onFilterChange(e, property)} />
                        {trait}
                      </label>              
                      <div className="justify-self-end">{count}</div>
                    </div>
                  ))}
                </AccordionBody>
              </Accordion>
            ))}
          </div>
        </div>
      </Tabs>
    );
}

export default CollectionSideBar;